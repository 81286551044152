import { LinksTabs } from "@/components/LinksTabs";
import { GetStaticProps, HomePageTwo } from "../bet-nextv1/HomePageTwo";

import type { NextPage } from "next";

const HomeKkr: NextPage = (props: any) => {
	const props2 = Object.assign({}, props, {
		leftMenu: LinksTabs,
	});

	return <HomePageTwo {...props2} />;
};

export default HomeKkr;

export const getStaticProps = GetStaticProps;
