import { collectionDocument } from "@/core-nextv3/document/document.api";
import { CASINO_SETTING } from "@/setting/setting";

export async function getCasinoProviders() {
	const result = await collectionDocument(
		CASINO_SETTING.merge({
			perPage: 100,
			where: [
				{
					field: "_level",
					operator: "==",
					value: 1,
				},
				// {
				//     field    : "status",
				//     operator : "==",
				//     value    : true,
				// },
			],
		}),
	);

	const providers: any[] = [];

	if (result?.collection) {
		result?.collection.forEach((item: any) => {
			if (item.id.startsWith("2")) {
				providers.push(item);
			}
		});
	}

	const sortedProviders = providers.sort((a, b) => {
		const A =
			a.order === undefined || a.order === 0
				? Number.POSITIVE_INFINITY
				: a.order;
		const B =
			b.order === undefined || b.order === 0
				? Number.POSITIVE_INFINITY
				: b.order;

		return A - B;
	});

	return sortedProviders;
}
