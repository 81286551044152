import redirectTo from "@/bet-nextv1/utils/redirectTo";
import { useSubCore } from "@/hooks/useSubCore";
import { THEME_SETTING } from "@/setting/setting";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useContext } from "react";
import { FaPlus } from "react-icons/fa";
import { HiOutlineDocumentText } from "react-icons/hi2";
import { RiMegaphoneLine } from "react-icons/ri";
import { AuthContext } from "../../context/AuthContext";
import styles from "./styles.module.scss";

export const NoticeHome: React.FC<any> = ({ posts }) => {
	// console.log("posts", posts)
	// const router = useRouter()
	const { t } = useTranslation();
	const { user } = useContext(AuthContext);
	const router = useRouter();
	const { setOpenLoginModal } = useSubCore();

	return (
		<div className={styles.noticeHome}>
			<div className={styles.title}>
				<RiMegaphoneLine /> {t("Avisos / Regras")} <span>Notice/Rules</span>
				<span
					className={styles.more}
					onClick={() => {
						redirectTo("/deposit/?tabIndex=7", user, router, setOpenLoginModal);
					}}
				>
					<FaPlus /> More
				</span>
			</div>

			<div className={styles.listNotices}>
				{posts?.map((post: any, key: any) => (
					<div
						key={key}
						className={styles.notice}
						onClick={() =>
							redirectTo(
								`/deposit?tabIndex=7&clickedId=${post.id}`,
								user,
								router,
								setOpenLoginModal,
							)
						}
					>
						<p>
							{!THEME_SETTING.themeCasino && <HiOutlineDocumentText />}
							{!THEME_SETTING.themeCasino && <span>RULE </span>}
							{post?.title}
						</p>
						<span>more</span>
					</div>
				))}
			</div>
		</div>
	);
};
