import { BannerSlider } from "@/bet-nextv1/HomeComponents/BannerSlider";
import { EventHome } from "@/bet-nextv1/HomeComponents/EventHome";
import { NoticeHome } from "@/bet-nextv1/HomeComponents/NoticeHome";
import { AuthContext } from "@/bet-nextv1/context/AuthContext";
import redirectTo from "@/bet-nextv1/utils/redirectTo";
import { useResponsive } from "@/core-nextv3/util/useResponsive";
import { useSubCore } from "@/hooks/useSubCore";
import { THEME_SETTING } from "@/setting/setting";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import router from "next/router";
import { useContext } from "react";
import { FaPlus } from "react-icons/fa";
import { BannersLinks } from "../BannersLinks";
import { CardLive } from "../CasinoComponents/CardLive";
import { CardSlotProvider } from "../CasinoComponents/CardSlotProvider";
import { ImageSetBet } from "../ImageSetBet";
import { getProxiedImageUrl } from "../ImageSetBet/utils";
import styles from "./styles.module.scss";

export const HomeThree: React.FC<any> = ({
	posts,
	events,
	homePage,
	bannersGames,
	sizeBanners,
	casino,
	slot,
	providers,
	deactivatedCasino,
}: any) => {
	// console.log("HomeTwo", homePage)
	const { isDesktop } = useResponsive();
	const { t } = useTranslation();
	const { setOpenLoginModal } = useSubCore();
	const { isClient } = useResponsive();

	const { user } = useContext<any>(AuthContext);

	if (!isClient) {
		return <></>;
	}

	// const handleBannerClick = (link: any) =>
	// {
	//     if (link)
	//     {
	//         window.open(link);
	//     }
	// };

	return (
		<div className={styles.homeThree}>
			{/* {!isDesktop && (
                <div className={styles.informationsMonney}>
                    <div className={styles.inputInformation}>
                        <div className={styles.inputItem}>
                            <img src='/assets/img/sportscash.png' alt='' />
                            <label>{t("Dinheiro Esporte")}</label>
                        </div>
                        <span className={styles.value}>
                            {user?.sport
                                ? new Intl.NumberFormat("en").format(user?.sport)
                                : 0}{" "}
                            {t("Won")}
                        </span>
                    </div>

                    <div className={styles.inputInformation}>
                        <div className={styles.inputItem}>
                            <img src='/assets/img/casinocash.png' alt='' />
                            <label>{t("Dinheiro do Cassino")}</label>
                        </div>
                        <span className={styles.value}>
                            {user?.casino
                                ? new Intl.NumberFormat("en").format(user?.casino)
                                : 0}{" "}
                            {t("Won")}
                        </span>
                    </div>
                </div>
            )} */}

			{/* {homePage?.bannersHome?.map((imageBanner: any) => (
        <div key={imageBanner?.id}>
          {isDesktop && <img alt='' src={imageBanner?.desktop?._url} />}
          {!isDesktop && <img alt='' src={imageBanner?.mobileImage?._url} />}
        </div>
      ))} */}
			{/* {isDesktop ? */}
			<BannerSlider images={homePage?.bannersHome} />

			<div className={styles.content}>
				{isDesktop && !THEME_SETTING.disabledHomeSecondRow && (
					<div className={styles.informations}>
						<NoticeHome posts={posts} />
						<EventHome events={events} />
						<BannersLinks sizeBanners={sizeBanners} />
					</div>
				)}

				{!isDesktop && !THEME_SETTING.disabledHomeMobileLinks && (
					<div className={styles.bannersGames}>
						<a
							onClick={() =>
								redirectTo("/liveCasino", user, router, setOpenLoginModal)
							}
						>
							<Image
								loader={getProxiedImageUrl}
								src={bannersGames[0].mobileImage?._url}
								alt=""
							/>
							<ImageSetBet src={bannersGames[0].mobileImage?._url} />
						</a>
						<a
							onClick={() =>
								redirectTo("/slotsCasino", user, router, setOpenLoginModal)
							}
						>
							<ImageSetBet src={bannersGames[1].mobileImage?._url} alt="" />
						</a>
					</div>
				)}
				{/* <LinksTabs casino={casino} slot={slot} providers={providers}/> */}
				{THEME_SETTING.alternateProvidersLinksComponent ? (
					<THEME_SETTING.alternateProvidersLinksComponent
						casino={casino}
						slot={slot}
						providers={providers}
						deactivatedCasino={deactivatedCasino}
					/>
				) : (
					<>
						{isDesktop && (
							<>
								<div className={styles.titleInformation}>
									<img src="/assets/icon/Live-Casino.png" alt="" />{" "}
									{t("live casino")}
									<span
										className={styles.more}
										onClick={() => {
											redirectTo(
												"/liveCasino",
												user,
												router,
												setOpenLoginModal,
											);
										}}
									>
										<FaPlus /> More
									</span>
								</div>
								<div className={styles.items}>
									{casino?.map(
										(provider: any) =>
											provider?.status && (
												<CardLive
													game={provider}
													key={provider.id}
													maintenance={
														provider?.maintenance || deactivatedCasino
													}
												/>
											),
									)}
								</div>
							</>
						)}

						{isDesktop && (
							<>
								<div className={styles.titleInformation}>
									<ImageSetBet src="/assets/icon/Slot.png" alt="" /> {t("Slot")}
									<span
										className={styles.more}
										onClick={() => {
											redirectTo(
												"/slotsCasino/",
												user,
												router,
												setOpenLoginModal,
											);
										}}
									>
										<FaPlus /> More
									</span>
								</div>
								<div className={styles.items}>
									{providers?.map(
										(provider: any) =>
											provider?.status && (
												<CardSlotProvider
													provider={provider}
													key={provider.id}
													maintenance={
														provider?.maintenance || deactivatedCasino
													}
												/>
											),
									)}
								</div>
							</>
						)}
					</>
				)}

				{THEME_SETTING.thirdRowComponent && (
					<THEME_SETTING.thirdRowComponent
						posts={posts}
						events={events}
						sizeBanners={sizeBanners}
					/>
				)}

				{/* {!isDesktop && (
                    <div className={styles.navigationMobile}>
                        <div className={styles.upperLinks}>
                            <div
                                className={styles.upperLinksButton}
                                onClick={() => redirectTo("/deposit?tabIndex=0", user, router, setOpenLoginModal)}
                            >
                                <img src='/assets/img/deposito.png' alt='' />
                                <p>{t("Depósito")}</p>
                            </div>

                            <div
                                className={styles.upperLinksButton}
                                onClick={() => redirectTo("/deposit?tabIndex=1", user, router, setOpenLoginModal)}
                            >
                                <img src='/assets/img/payout.png' alt='' />
                                <p>{t("Saque")}</p>
                            </div>

                            <div
                                className={styles.upperLinksButton}
                                onClick={() => redirectTo("/deposit?tabIndex=2", user, router, setOpenLoginModal)}
                            >
                                <img src='/assets/img/movimentacao.png' alt='' />
                                <p>{t("Movimentação")}</p>
                            </div>

                            <div
                                className={styles.upperLinksButton}
                                onClick={() => redirectTo("/deposit?tabIndex=4", user, router, setOpenLoginModal)}
                            >
                                <img src='/assets/img/customer.png' alt='' />
                                <p>{t("Perguntas")}</p>
                            </div>
                        </div>
                        <NavigationHomeMobile />
                    </div>
                )} */}

				{/* <div className={styles.bannersHome}>
                    {homePage?.bannersGames?.map((miniBanners: any, index: any) => (
                        <div className={styles.bannerItem} key={index}>
                            {isDesktop && (
                            // eslint-disable-next-line @next/next/no-img-element
                                <img
                                    className={styles.classBannerImg}
                                    alt=''
                                    src={miniBanners?.desktop?._url}
                                    onClick={() => redirectTo(miniBanners?.url, user, router, setOpenLoginModal)}
                                />
                            )}

                            {!isDesktop && (
                                <img
                                    className={styles.overLayImg}
                                    alt=''
                                    src={miniBanners?.mobile?._url}
                                    onClick={() => redirectTo(miniBanners?.url, user, router, setOpenLoginModal)}
                                />
                            )}
                            <div className={styles.bannerOverlay}>
                                <div className={styles.overLayImg}><img src={"assets/img/logo.png"}></img></div>
                                <span className={styles.overLayText}>스포츠</span>
                                <span className={styles.overLayButton}>배팅하러 가기</span>
                            </div>
                        </div>
                    ))}
                </div>  */}

				{THEME_SETTING.overrideSponsors ? (
					<div className={styles.sponsorsOverride}>
						<ImageSetBet src="/assets/img/opartners.png" />
					</div>
				) : (
					<div className={styles.sponsors}>
						{homePage?.imageSponsors?.map((logo: any, index: number) => (
							<ImageSetBet alt="" key={index} src={logo?.image?._url} />
						))}
					</div>
				)}

				{!isDesktop && (
					<div>
						<BannersLinks sizeBanners={sizeBanners} />
					</div>
				)}
			</div>
		</div>
	);
};
