import { ImageSetBet } from "@/bet-nextv1/ImageSetBet";
import { AuthContext } from "@/bet-nextv1/context/AuthContext";
import redirectTo from "@/bet-nextv1/utils/redirectTo";
import { useSubCore } from "@/hooks/useSubCore";
import { Splide } from "@splidejs/react-splide";
import type { Options } from "@splidejs/splide";
import { useRouter } from "next/router";
import { useContext } from "react";
import { Desktop, Mobile } from "../../../core-nextv3/util/useResponsive";
import styles from "./styles.module.scss";

export const BannerSlider = ({ images }: any) => {
	const mainOptions: Options = {
		role: "banner",
		type: "loop",
		rewind: true,
		perPage: 1,
		perMove: 1,
		pagination: false,
		slideFocus: true,
		start: 0,
		arrows: true,
		autoplay: true,
		interval: 3000,
	};

	const { setOpenLoginModal } = useSubCore();
	const router = useRouter();
	const { user } = useContext(AuthContext);

	return (
		<div className={styles.bannerSlider}>
			<div className={styles.content}>
				<Splide options={mainOptions} className={styles.slider}>
					{images?.map((item: any, index: any) => (
						<div key={index} className="splide__slide">
							<Desktop>
								<button
									onClick={() =>
										redirectTo(item?.url, user, router, setOpenLoginModal)
									}
									aria-label="Banner"
									className="w-full"
								>
									<ImageSetBet
										aspectRatio={1920 / 813}
										image={item?.desktop}
										width={2000}
										priority
									/>
								</button>
							</Desktop>
							<Mobile>
								<button
									onClick={() =>
										redirectTo(item?.url, user, router, setOpenLoginModal)
									}
									aria-label="Banner"
									className="h-full w-full"
								>
									<ImageSetBet
										width={500}
										image={item?.mobileImage}
										style={{
											width: "100%",
											height: "100%",
											objectFit: "fill",
										}}
										priority={true}
									/>
								</button>
							</Mobile>
						</div>
					))}
				</Splide>
			</div>
		</div>
	);
};
