import redirectTo from "@/bet-nextv1/utils/redirectTo";
import { useSubCore } from "@/hooks/useSubCore";
import { THEME_SETTING } from "@/setting/setting";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useContext } from "react";
import { FaPlus } from "react-icons/fa";
import { GiPresent } from "react-icons/gi";
import { HiOutlineDocumentText } from "react-icons/hi2";
import { AuthContext } from "../../context/AuthContext";
import styles from "./styles.module.scss";

export const EventHome: React.FC<any> = ({ events }) => {
	const router = useRouter();
	const { t } = useTranslation();
	const { user } = useContext(AuthContext);
	const { setOpenLoginModal } = useSubCore();

	return (
		<div className={styles.eventHome}>
			<h1>
				<GiPresent />
				{t("Evento")} <span>EVENT</span>
				<span
					className={styles.more}
					onClick={() => {
						redirectTo("/event", user, router, setOpenLoginModal);
					}}
				>
					<FaPlus /> More
				</span>
			</h1>

			<div className={styles.listNotices}>
				{events?.map((event: any, key: any) => (
					<div
						className={styles.notice}
						key={key}
						onClick={() =>
							redirectTo(
								`/event?selectedId=${event.id}`,
								user,
								router,
								setOpenLoginModal,
							)
						}
					>
						<p>
							{!THEME_SETTING.themeCasino && <HiOutlineDocumentText />}
							{!THEME_SETTING.themeCasino && <span>EVENT</span>}
							{event?.title}
						</p>
						<span>more</span>
					</div>
				))}
			</div>
		</div>
	);
};
