import withHeader from "@/bet-nextv1/utils/withHeader";
import {
	collectionDocument,
	getDocument,
} from "@/core-nextv3/document/document.api";
import { calls, revalidateResults } from "@/core-nextv3/util/call.api";
import { useResponsive } from "@/core-nextv3/util/useResponsive";
import { useSubCore } from "@/hooks/useSubCore";
import {
	CASINO_SETTING,
	EVENT_SETTING,
	HOME_PAGE_SETTING,
	POPUP_SETTING,
	POST_SETTING,
	REVALIDATE_SETTING,
} from "@/setting/setting";
import type { GetStaticProps, NextPage } from "next";
import { useEffect } from "react";
import { HomeThree } from "../HomeThree";
import { getCasinoProviders } from "../utils/getCasinoProviders";
import styles from "./styles.module.scss";

const HomePageTwo: NextPage = ({
	posts,
	events,
	homePage,
	popups,
	sizeBanners,
	casino,
	slot,
	providers,
	homeLinksComponent,
	account,
}: any) => {
	// console.log("casino", casino);
	// console.warn('POSTS', posts, events, homePage, popups)
	// console.warn(test)
	const { isClient } = useResponsive();
	const { setPopups } = useSubCore();

	useEffect(() => {
		setPopups(popups);
	}, []);

	if (!isClient) {
		return <></>;
	}

	return (
		<main className={styles.homePageTwo}>
			<HomeThree
				posts={posts}
				events={events}
				homePage={homePage}
				bannersGames={homePage?.bannersGames}
				sizeBanners={sizeBanners}
				casino={casino}
				slot={slot}
				providers={providers}
				HomeLinksComponent={homeLinksComponent}
				deactivatedCasino={account.deactivatedCasino}
			/>
		</main>
	);
};

export const getStaticProps: GetStaticProps = ({ locale }: any) =>
	withHeader(async (props: any) => {
		// precisa de testes
		if (props?.revalidateWithHeader) {
			throw new Error("withHeader Error");
		}

		const [resultPosts, resultEvents, resultHomePage, resultPopups] =
			await calls(
				collectionDocument(POST_SETTING.merge({ perPage: 50 })),
				collectionDocument(EVENT_SETTING.merge({ perPage: 50 })),
				getDocument(HOME_PAGE_SETTING),
				collectionDocument(POPUP_SETTING.merge({ perPage: 50 })),
			);

		const result = revalidateResults([
			resultPosts,
			resultEvents,
			resultHomePage,
			resultPopups,
		]);

		if (result.revalidate) {
			return result;
		}

		const casinoResult = await collectionDocument(
			CASINO_SETTING.merge({
				perPage: 100,
				orderBy: "order",
				where: [
					{
						field: "_level",
						operator: "==",
						value: 2,
					},
					{
						field: "type.value",
						operator: "==",
						value: "lobby",
					},
				],
			}),
		);

		const slotResult = await collectionDocument(
			CASINO_SETTING.merge({
				perPage: 100,

				where: [
					{
						field: "_level",
						operator: "==",
						value: 2,
					},
					{
						field: "type.value",
						operator: "==",
						value: "slot",
					},
					{
						field: "status",
						operator: "==",
						value: true,
					},
				],
			}),
		);

		return {
			props: {
				locale: locale,
				posts: resultPosts?.collection || [],
				events: resultEvents?.collection || [],
				homePage: resultHomePage?.data || [],
				popups: resultPopups?.collection || [],
				casino: casinoResult?.collection || [],
				slot: slotResult?.collection || [],
				providers: (await getCasinoProviders()) || null,
				account: props.account,
				homeLinksComponent: null,
			},
			revalidate: REVALIDATE_SETTING,
		};
	});

export { getStaticProps as GetStaticProps, HomePageTwo };
